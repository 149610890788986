.filename {
  margin-bottom: 5px;
}

.progressText {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.progressTextSize {
  white-space: nowrap;
}
