.wagonTagsGroup + .wagonTagsGroup {
  margin-top: calc(var(--pt-grid-size) * 2);
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  padding-top: calc(var(--pt-grid-size) * 2);
}

.headingAndButton {
  display: flex;
  justify-content: space-between;
}
