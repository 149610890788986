.main {
  display: block;
  border: 2px dashed;
  padding: calc(var(--pt-grid-size) * 3);
  color: var(--gray5);
  text-align: center;
  cursor: pointer;
  transition: color 200ms;
}

.main:hover {
  color: var(--gray3);
}

/* increase specificity */
.main.main_invalid {
  color: var(--pt-intent-danger);
}

.iconWrap {
  margin-bottom: var(--pt-grid-size);
}
