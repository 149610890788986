.wagonCellPhotoButtonPopoverContent {
  padding: calc(var(--pt-grid-size) * 2);
  min-height: 150px;
  height: 300px;
  overflow: auto;
  resize: vertical;
}

.wagonCellPhotoButtonPopoverContentImage {
  max-width: none;
  max-height: 100%;
  width: auto;
}

.cellCenterContainer {
  text-align: center;
  display: block;
}
