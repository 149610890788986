.main:global(.bp3-dialog) {
  width: 1000px;
}

.main:global(.bp3-alert) {
  max-width: none;
}

.main :global(.bp3-alert-contents) {
  width: 100%;
}

.table {
  width: 100%;
}
