.root {
  border: 1px solid rgba(16, 22, 26, 0.3);
  padding: 0 calc(var(--pt-grid-size) / 2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form {
  padding: calc(var(--pt-grid-size) * 2);
  width: calc(var(--pt-grid-size) * 35);
}
