.root {
  border: 0;
  padding: 0;
  background: none;
  color: var(--pt-link-color);
  font: inherit;
  cursor: pointer;
}

.root:hover {
  text-decoration: underline;
}
