.root {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.root:not(.root_hasChildren) {
  padding-left: 10px;
}

.controls {
  width: 24px;
}

.chevron {
  border: 0;
  padding: 0 4px;
  background: none;
  color: #5c7080;
  cursor: pointer;
}

.iconWrapper {
  padding-right: var(--pt-grid-size);
  width: 26px;
  color: #5c7080;
}

.content {
  flex: 1 1 0;
  min-width: 0;
}

.content:not(.content_noPadding) {
  padding-right: var(--pt-grid-size);
}
