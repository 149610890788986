.root {
  display: flex;
  align-items: center;
}

.selectWrapper {
  margin-right: 5px;
}

.statusIndicatorWrapper {
  margin: 7px 0 0 5px;
}
