.root {
  overflow: auto;
}

.root_isFetching {
  opacity: 0.25;
  pointer-events: none;
}

.table {
  table-layout: fixed;
  width: 100%;
}

:global(.bp3-html-table.bp3-interactive) tbody .row:hover td {
  cursor: auto;
}

:global(.bp3-html-table.bp3-interactive) tbody .row_selected td {
  background-color: rgba(191, 204, 214, 0.4);
}

:global(.bp3-html-table.bp3-interactive) tbody .row_selected:hover td {
  background-color: rgba(191, 204, 214, 0.7);
}
