.addContactToolbar {
  display: flex;
}

.addContactToolbarAutocompleteWrapper {
  flex: 1 0 auto;
}

.addContactToolbarButtonWrapper {
  margin-top: 23px;
  margin-left: var(--pt-grid-size);
}

.addContactToolbarPopoverContent {
  padding: calc(var(--pt-grid-size) * 2);
  width: 400px;
}

.actionsCellButton + .actionsCellButton {
  margin-left: var(--pt-grid-size);
}
