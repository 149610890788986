.main {
  list-style: none;
  margin: 0 calc(0px - var(--pt-grid-size));
  padding: 0;
}

.item {
  display: inline-block;
  vertical-align: top;
  padding: 0 var(--pt-grid-size);
  width: 100%;
}
