.root {
  display: flex;
}

.list {
  flex: 0 0 200px;
}

.form {
  flex: 0 1 100%;
  border-left: 1px solid var(--pt-divider-black);
  padding-left: var(--pt-grid-size);
  min-width: 0;
}
