.dialog:global(.bp3-dialog) {
  display: flex;
  flex-direction: column;
  width: 980px;
  min-height: calc(100vh - var(--pt-grid-size) * 6);
}

.form {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.form :global(.bp3-dialog-body) {
  display: flex;
  flex-direction: column;
}
