.root {
  margin-bottom: var(--pt-grid-size);
}

.titleAndDragHandle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dragHandle {
  margin-left: var(--pt-grid-size);
}
