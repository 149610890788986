.square {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid rgba(16, 22, 26, 0.2);
  width: 32px;
  height: 16px;
}

.label {
  vertical-align: middle;
}
