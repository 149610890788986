.root {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: calc(var(--pt-grid-size) * 40);
}
