.inputGroupAndLink {
  display: flex;
}

.linkButton {
  margin-left: var(--pt-grid-size);
}

.dropdown {
  position: absolute;
  z-index: var(--pt-z-index-overlay);
  margin: 3px 0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  background: var(--white);
  line-height: calc(var(--pt-grid-size) * 3);
}

.optionList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option {
  padding: 0 var(--pt-grid-size);
  white-space: nowrap;
  cursor: pointer;
}

.option:hover,
.option_active {
  background: var(--light-gray1);
}

.noOptionsMessage {
  color: var(--light-gray1);
  text-align: center;
}
