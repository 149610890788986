.root {
  display: flex;
  flex-direction: column;
  padding: var(--pt-navbar-height) 0 0;
  min-height: 100vh;
}

.favoriteDocsSwitch {
  margin-right: var(--pt-grid-size);
}

.userNameIcon {
  margin-right: var(--pt-grid-size);
}

.menuAndContent {
  flex: 1 1 auto;
  display: flex;
}

.menuWidth {
  width: calc(var(--pt-grid-size) * 27);
}

.menuPlaceholder {
  composes: menuWidth;
  flex: 0 0 auto;
}

.menuWrapper {
  composes: menuWidth;
  position: fixed;
  top: var(--pt-navbar-height);
  bottom: 0;
  left: 0;
  border-right: 1px solid var(--light-gray1);
  padding: var(--pt-grid-size);
  overflow: auto;
}

.menuLink_collapsible {
  font-weight: bold;
}

.layoutMenuItemBadgeRoot {
  padding: 2px 6px;
  border-radius: 3px;
  background: #137cbd;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}

.layoutContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: calc(var(--pt-grid-size) * 3);
  min-width: 0;
}
