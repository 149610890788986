.requestItem {
  margin-top: var(--pt-grid-size);
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  padding-top: var(--pt-grid-size);
}

.requestItemHeader {
  display: flex;
}

.requestItemToggleAndName {
  flex: 1 1 0;
  display: flex;
  min-width: 0;
}

.requestItemToggleIcon {
  margin-right: var(--pt-grid-size);
  cursor: pointer;
}

.requestItemNameLink {
  min-width: 0;
  word-break: break-all;
}

.requestItemHeaderCount {
  flex: 0 0 80px;
  margin-left: var(--pt-grid-size);
  padding: 0 var(--pt-grid-size);
  text-align: right;
}

.requestItemStatesTableWrapper {
  margin-top: 6px;
  margin-left: 16px;
}

.requestItemStatesTable {
  width: 100%;
}

:global(.bp3-html-table) .requestItemNumberCell {
  width: 80px;
  text-align: right;
}
