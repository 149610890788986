.filesTableWrapper {
  overflow: auto;
  margin-bottom: var(--pt-grid-size);
  max-height: 300px;
}

.filesTable {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.filesTableCell {
  border-bottom: 1px solid var(--light-gray1);
  padding: calc(var(--pt-grid-size) / 2) 0;
  padding-left: calc(var(--pt-grid-size) * 2);
}

.filesTableCell:first-child {
  padding-left: 0;
}

.filesTableCell_isRemoveBtnCell {
  width: 45px;
}

.fileLink {
  word-break: break-word;
}
