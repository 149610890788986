.root {
  display: flex;
  width: 100%;
}

.root::before {
  content: '';
}

.root::before,
.pageSizeToolbarWrapper {
  flex: 1 1 0;
}

.pageNumbers {
  flex-shrink: 0;
}

.pageSizeInputWrapper {
  width: 90px;
}
