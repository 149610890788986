.widgetsColumn {
  --border-width: 2px;
  --neg-offset: 8px;
  margin: calc(0px - var(--neg-offset));
  border: var(--border-width) dashed transparent;
  padding: calc(var(--neg-offset) - var(--border-width));
  min-height: 200px;
  border-radius: 10px;
}

.widgetsColumn_isDragging {
  border-color: rgba(16, 22, 26, 0.15);
}

.widgetsColumn_isDraggingOver {
  border-color: rgba(16, 22, 26, 0.45);
}
