.root {
  margin: calc(0px - var(--pt-grid-size) / 2);
  display: flex;
  flex-wrap: wrap;
}

.root_align_center {
  justify-content: center;
}

.root_align_right {
  justify-content: flex-end;
}

.root_valign_center {
  align-items: center;
}

.root_noWrap {
  flex-wrap: nowrap;
}

.item {
  margin: calc(var(--pt-grid-size) / 2);
}
