.wagonNumberLink {
  font-family: monospace;
}

.popover :global(.bp3-popover-content) {
  padding: calc(var(--pt-grid-size) * 2);
  width: 300px;
}

.cellCenterContainer {
  text-align: center;
  display: block;
}
